import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { DatabusService } from "src/app/shared/services/databus.service";
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../../services/api.service';
import { userCounts } from 'src/app/config/endpoints';

@Component({
  selector: 'app-header-sidebar',
  templateUrl: './header-sidebar.component.html',
  styleUrls: ['./header-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderSidebarComponent {

  public user:any;

  public iconSidebar;
  public menuItems: Menu[];

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;
  public counts: any = null;
  public totalCashReward: number = 0;
  public totalPoints: number = 0;
  public purchaseCount: any = 0;

  constructor(private router: Router, public navServices: NavService,
    private _databusService: DatabusService,
    private _cookieService: CookieService,
    private _apiService: ApiService,
    public layout: LayoutService) {
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) { return false; }
            items.children.filter(subItems => {
              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) { return false; }
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });

  }

  ngOnInit() {
    this._databusService.userProfileDetails$.subscribe((user: any) => {
      this.user = user;
      this.purchaseCount = parseInt(user?.purchaseCount);

      this.totalCashReward = parseInt(user?.rewardCount?.rewardDetails[0]?.totalCashReward);
    });
    this.getCounts();
  }
  getCounts(): void{
    this._apiService.makeGetRequest(userCounts).subscribe((response: any) => {
      this.counts = response.data;
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }
  logout(): void { 
  this._cookieService.deleteAll('/', '');
  this.router.navigateByUrl('/login');
  return;
  }
}
