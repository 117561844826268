<div class="justify-content-center">
  <div class="header-logo">
    <a class="logo d-block text-center" routerLink="/">
      <img class="img-fluid for-light" src="assets/images/LoanLogo.png" alt="looginpage" />
    </a>
</div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
     
      <div class="login-card">
        <div>
          <div>
            <a class="logo">
              <!-- <img class="img-fluid for-light loginimg" src="assets/images/Business-Loan.png" alt="looginpage" /> -->
              <img class="img-fluid for-light mt-1" [src]="headerImage" onerror="this.onerror=null;this.src='assets/images/Business-Loan.png';" alt="looginpage" />
            </a>
          </div>
          <div class="login-main login-form" *ngIf="!newUser">
            <form class="theme-form" [formGroup]="loginForm">

              <div class="form-group mb-3" #loginMain *ngIf="!otpReceived && !newUser">
                <input class="form-control mb-2" type="text" required placeholder="Enter First Name & Last Name"
                       formControlName="firstName" name="firstName"/>
                <div *ngIf="loginForm.get('firstName').invalid && loginForm.get('firstName').touched">
                  <small class="text-sm text-danger"
                         *ngIf="loginForm.get('firstName').hasError('required')">First Name is required.</small>
                         
                         <!-- <small class="text-sm text-danger" *ngIf="loginForm.get('firstName').hasError('pattern')">Enter Full Name ( First & Last  Name ) </small> -->
                </div>
                <!-- <input class="form-control mb-2" type="text" required placeholder="Enter Last Name"
                       formControlName="lastName" name="lastName"/>
                <div *ngIf="loginForm.get('lastName').invalid && loginForm.get('lastName').touched">
                  <small class="text-sm text-danger" *ngIf="loginForm.get('lastName').hasError('required')">Last
                    Name is required.</small>
                </div> -->
                <div class="row">
                  <div class="col-4 col-md-2"> 
                    <ng-select [items]="countries" bindValue="phonecode" placeholder="+91"
                    [formControlName]="'countryCode'" id="countryCode" [searchFn]="customSearch"
                    class="countrycodename" [clearable]="false" [ngModel]="selectedCountry?.flag && selectedCountry?.phonecode">
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.flag }} (+{{ item.phonecode }})
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div>
                        {{ item.flag }} (+{{ item.phonecode }})
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
                  <div class="col-8 col-md-10"> 
                    <input class="form-control mb-2" type="tel" required placeholder="XXX-XXX-XXXX" formControlName="phone"/>
                    <div *ngIf="loginForm.get('phone').invalid && loginForm.get('phone').touched">
                      <small class="text-sm text-danger" *ngIf="loginForm.get('phone').hasError('required')">Phone Number is
                        required.</small>
                        <small class="text-sm text-danger" *ngIf="loginForm.get('phone').hasError('pattern')">Enter Valid
                          Phone Number</small>
                        </div>
                      </div>
                      <small class="text-sm text-primary mb-2"> *Please Enter WhatsApp Number Only.</small>
                
                </div>
                <div class="text-end mt-1">
                  <button class="btn btn-primary d-block w-100 register" [disabled]="loginForm.controls.phone.invalid || loginForm.controls.firstName.invalid"
                          (click)="otpRequest()" type="button">
                    <span>Register Now for FREE</span>
                  </button>
                </div>
              </div>
              <div class="form-group mb-3" *ngIf="otpReceived && !newUser">
                <span class="text-success text-sm">OTP Sent To Your Whatsapp Number</span>
                <br/>
                <br/>
                <label class="col-form-label">Enter OTP</label>
                <input class="form-control mb-2" type="text" required placeholder="Enter OTP" formControlName="otp"/>
                <div class="text-end">
                  <a [ngClass]="show ? 'text-sm text-primary enable-a': 'disabled-a m text-primary'"
                     (click)="otpRequest()">
                    <small>
                      {{ !show ? "Resend OTP After " + OTPCountDown + " Sec." : 'Resend OTP'}}
                    </small>
                  </a>
                </div>
                <div *ngIf="loginForm.get('otp').invalid && loginForm.get('otp').touched">
                  <small class="text-sm text-danger" *ngIf="loginForm.get('otp').hasError('required')">OTP is
                    required.</small>
                  <small class="text-sm text-danger" *ngIf="loginForm.get('otp').hasError('pattern')">Enter Valid
                    OTP</small>
                </div>
                <div *ngIf="newUserSignUp == true">
                  <label for="edo-ani"> <input (click)="TermAndConditionClick()"  class="checkbox-primary" type="checkbox" name="termAndCondition"/> <span class="text-primary" style="font-size: 13px;" (click)="showMoreTAC=!showMoreTAC">Agree With Terms & Condition,</span>
                    <br/>
                    <div *ngIf="showMoreTAC">
                      I declare that the information I have provided is correct and complete to the best of my knowledge. I hereby 
                      authorize Loan N Reward and its affiliates to call, email, send a Whatsapp  me in relation to any of their services. The consent herein shall override any 
                      registration for DNC/NDNC.*
                    </div>
                  </label>
                  <p></p>
                </div>
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-inline-block w-40 me-2" (click)="otpVerification()" type="button" [disabled]="loginForm.controls.otp.invalid || (newUserSignUp && !TermAndCondition)">
                    <span>Submit</span>
                  </button>
                  <button class="btn btn-primary d-inline-block w-40" (click)="otpReceived = !otpReceived"
                          type="button">
                    <span>Back</span>
                  </button>
                </div>
              </div>
              <div class="form-group mb-3" *ngIf="newUser && !otpReceived">
                <label class="col-form-label">Please Enter Details</label>
                <input class="form-control mb-2" type="text" required placeholder="Enter OTP" formControlName="otp"/>
                <div class="row text-end mt-3">
                  <button class="btn btn-primary d-block w-40" [disabled]="loginForm.controls.otp.invalid"
                          (click)="otpVerification()" type="button">
                    <span>Submit</span>
                  </button>
                </div>
                <div *ngIf="newUserSignUp == true">
                  <label for="edo-ani"> <input class="radio_animated" id="edo-ani" type="radio" name="rdo-ani" checked="" />Text </label>
                  <p>I declare that the information I have provided is coorect and complete to the best of my knowledge. I hereby 
                      authorize Loan N Reward and its affiliates to call, email, send a Whatsapp  me in relation to any of their services. The consent herein shall override any 
                      registration for DNC/NDNC.*</p>
                </div>
              </div>
            
            </form>
            
          </div>
          <!-- <div class="login-main login-form" *ngIf="newUser">
            <form class="theme-form" [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
              <h4>Enter Details</h4>
              <div class="form-group">
                <label class="col-form-label">Please Enter Your City</label>
                <select class="form-control" formControlName="city" name="city" >
                  <option value="">Select City</option>
                  <option *ngFor="let city of cities" [value]="city.name">{{city.name}}</option>
                </select>
                <div *ngIf="registrationForm.get('city').invalid && registrationForm.get('city').touched">
                  <small class="text-sm text-danger"
                    *ngIf="registrationForm.get('city').hasError('required')">First Name is required.</small>
                </div>

                <div class="row text-end mt-3">
                  <button class="btn btn-primary d-block w-40" [disabled]="registrationForm.invalid" type="submit">
                    <span>Register Now for FREE</span>
                  </button>
                </div>
              </div>
            </form>

          </div> -->
          <div class="login-main">
            <div class="text-center ">
              <p class="registerText">{{textMessage}}</p>

              <p><b>{{referralUser}}  </b>{{referralUser !== '' ? '&':''}}<b>{{userRegisteredCount}}+ </b> have registered already!</p>

              <!-- <img class="img-fluid for-light" src="assets/images/batch_image_world_record_new.webp" alt="looginpage" /> -->
              <div class="Headdetails text-center bg-primary text-white p-2 ">
                <img class="img-fluid for-light " [src]="footerImage" onerror="this.onerror=null;this.src='assets/images/5.png';" alt="looginpage" />
              </div>
            </div>
            <div class="card" *ngIf="products.length > 0">
              <div class="card-header text-center">
                <h4 class="card-title mb-0">Loans Category</h4>
              </div>
              <div class="flex" style="width: 300px;padding-bottom: 50px;">
              <owl-carousel-o [options]="owlcarousel2Options">
                <ng-container *ngFor="let product of products">
                <ng-template carouselSlide *ngIf=" product.status">
                  <div class="purchase-card discover" >
                    <img class="img-fluid" [src]="product.serviceImage" alt="vector discover" />
                    <div class="card-body pt-3">
                      <h5 class="mb-1">{{product.name}}</h5>
                      <p class="f-light">{{product.shortDescription}}</p>
                      <!-- <a class="purchase-btn btn btn-hover-effect btn-primary f-w-500" (click)="openService(product._id)">Enquiry</a> -->
                    </div>
                  </div>
                </ng-template>
              </ng-container>
              </owl-carousel-o>
            </div>
                <!-- <ngb-carousel
                >
                  <ng-template ngbSlide *ngFor="let product of products">     
                    <div class="carousel-caption">
                      <div class="purchase-card discover">
                        <img class="img-fluid" [src]="product?.serviceImage" alt="vector discover" />
                        <div class="card-body pt-3">
                          <h5 class="mb-1">{{product.name}}</h5>
                          <p class="f-light text-dark">{{product.shortDescription}}</p>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-carousel> -->
              </div>
           
            <div class="card bg-primary text-center">
              <h4 class="card-title pt-3 text-white">Testimonial </h4>
              <!-- <ngb-carousel> -->
                <div class="text-center" style="width: 300px;">

              <owl-carousel-o [options]="owlcarousel2Options">
                <ng-template carouselSlide *ngFor="let testimonial of testimonials">
                <!-- <ng-template ngbSlide >      -->
                  <div class="carousel-caption">
                    <div class="purchase discover">
                      <!-- <img class="img-fluid for-light testimonial-img" src="assets/images/images-removebg-preview.png"
                      alt="looginpage" /> -->
                      <img [src]="testimonial.customerImage" onerror="this.onerror=null;this.src='assets/images/usertest.png';"
                      alt="user" width="50" class="img-fluid for-light testimonial-img" />
                           <div class="card-body pt-1">
                        <h5 class="mb-1 text-white">{{testimonial.customerName}}</h5>
                        <p class="f-light text-dark text-white">{{testimonial.testimonialDetails}}</p>
                      </div>
                    </div>
                  </div>
                </ng-template>
              <!-- </ngb-carousel> -->
              </owl-carousel-o>
          </div>
         
        
          
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-header" style="height: 100px;">
</div>
<div class="register-div" *ngIf="showRegisterDiv && !otpReceived && !newUser">
  <div class="text-center mt-3">
    <button class="btn btn-primary d-block w-100 register" (click)="scrollToLoginForm()" type="button">
      <span>Register Now for FREE</span>
    </button>
    <p><b>{{userRegisteredCount}}+ </b>have registered already!</p>
    <!-- <a (click)="openAccepctModel()" class="termsandcondition">
      <span>Terms And Condition</span>
    </a> -->
  </div>
</div>

<ng-template #AccepctModel let-modal>
  <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel1"> Terms and Conditions</h5>
  </div>
  <div class="modal-body" style="max-height: 300px; overflow-y: auto;">
    <div *ngFor="let item of termsandconditions" class="" role="alert">
      <p [innerHTML]="item.termsandcondition || 'No terms and conditions found' "></p>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-center">
    <!-- <div class="form-check checkbox checkbox-primary mb-0">
        <label class="form-check-label" for="checkbox-primary-1">I agree With this Terms and Conditions</label>
    </div> -->
</div>

</ng-template>


<ng-template #Success let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel1">Congratulations 🎉 🥳 {{ this.user.firstName }}!</h5>
    <button class="btn-close" type="button" data-dismiss="modal" aria-label="Close" (click)="closeAllModal()"></button>
  </div>
  <div class="modal-body text-center">
    <div class="" role="alert">
      <img width="200" src="assets/images/Studentloaan.gif" alt="success" class="me-3" />
      <p>Congratulations! Thank you for Signing up for Loan N Reward.
        </p>
      <p>You are eligible for Rs 500 Reward Bonus.</p>
      <p>You will get this reward along with your or referrals loan disbursement.</p>
      <p>Thank you.</p>
      <hr>
    </div>
  </div>
</ng-template>
