import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import * as feather from 'feather-icons';
import { LayoutService } from '../../../services/layout.service';
import { NavService } from '../../../services/nav.service';
import { fadeInAnimation } from '../../../data/router-animation/router-animation';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [fadeInAnimation]
})
export class ContentComponent implements OnInit, AfterViewInit {
  isHoveringOverPageBody: boolean = false;

  constructor(private route: ActivatedRoute, public navServices: NavService, 
    public layout: LayoutService) {
      
      this.route.queryParams.subscribe((params) => {
        this.layout.config.settings.layout = params.layout ? params.layout : this.layout.config.settings.layout
      })
  }
    
  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  public getRouterOutletState(outlet: any): any {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
  sidebarToggle() {
    this.navServices.collapseSidebar = window.innerWidth < 991 || this.isHoveringOverPageBody;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.navServices.collapseSidebar = window.innerWidth < 991;
  }

  handleMouseEnter() {
    this.isHoveringOverPageBody = true;
    this.sidebarToggle();
  }

  handleMouseLeave() {
    this.isHoveringOverPageBody = false;
    this.sidebarToggle();
  }
  get layoutClass() {
    
    return  "compact-wrapper";

  }
  

 
  
  ngOnInit() {
    this.onResize(null);
  }

}
