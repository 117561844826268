import { Component, OnInit } from '@angular/core';
import { DatabusService } from 'src/app/shared/services/databus.service';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from "src/app/shared/services/api.service";
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { friendInviteMessage, userRegisteredCount } from 'src/app/config/endpoints';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public userDetails: any = {};
  public message: string = '';
  public today: number = Date.now();
  public userRegisteredCount: any = 0;
  constructor(
    private _apiService: ApiService,
    private _cookieService: CookieService,
    private _databusService: DatabusService,
    private _toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this._databusService.userProfileDetails.subscribe((data: any) => {
      this.userDetails = data;
    });
    this._apiService.makeGetRequest(friendInviteMessage).subscribe((response: any)=>{
      this.message = (response.data?.description || '').replace(/\n/g, '%0a');
    });
    this.getUserRegisteredCount()
  }
  getUserRegisteredCount(): void {
    this._apiService.makeGetRequest(userRegisteredCount).subscribe((response: any) => {
        this.userRegisteredCount = response.data;
    });
  }

  copyURL(): void {
    console.log('copyURL')  
    const message = `
  Click below to REGISTER 👇🏻%0a
https://loannreward.com/login/${this.userDetails._id}%0a%0a

${this.message}

%0a%0a

*${this.userDetails.firstName} ${this.userDetails.lastName ? this.userDetails.lastName : ''} And ${this.userRegisteredCount}➕*%0a
Users have registered already!👇🏻 %0a
Link ➡ https://loannreward.com/login/${this.userDetails._id}
`;
    const URL = `https://api.whatsapp.com/send?text=${message}`
    window.open(URL, '_blank');
    this._toastrService.success('URL Copied', 'Success');
    return;
  }

}
