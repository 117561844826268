<!-- Page Header Start-->
<div class="page-header">
  <div class="header-wrapper row m-0 mb-2 align-items-center">
    <app-search></app-search>
    <div class="header-logo-wrapper col p-0">
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
      <div class="logo-wrapper">
        <h6>नमस्ते 🙏, <br/><b>{{user?.firstName}}. Ji!</b></h6>
      </div>
    </div>
    <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto">
      
      <ul class="nav-menus">

        <div class="rank-karma-wrapper">
          <p><b>{{ myRank }}</b> My Rank
          </p>
          <p><b>{{ totalPoints }}</b> Reward Points</p>
        </div>
        <!-- <li class="language-nav" (click)="languageToggle()">
          <app-languages></app-languages>
        </li> -->
        <!-- <li>
          <span class="header-search" (click)="searchToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#search"></use>
            </svg>
          </span>
        </li> -->
        <!-- <li class="onhover-dropdown">
          <app-bookmark></app-bookmark>
        </li> -->
        <!-- <li>
          <div class="mode" (click)="layoutToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#moon"></use>
            </svg>
          </div>
        </li> -->
        <!-- <li class="cart-nav onhover-dropdown">
          <app-cart></app-cart>
        </li> -->
        <!-- <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li> -->
        <!-- <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li> -->
      </ul>
    </div>
  </div>
</div>
<div class="horizontal-wrapper" [attr.icon]="layout.config.settings.icon" [class.close_icon]="navServices.collapseSidebar">
  <app-header-menu></app-header-menu>
</div>
<!-- Page Header Ends -->
