import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { DatabusService } from "src/app/shared/services/databus.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public profileImg: "https://angular.pixelstrap.com/cuba/assets/images/dashboard-4/user.png";
  public profile: any = {};
  constructor(
      public router: Router,
      private _databusService: DatabusService,
      private _cookieService: CookieService,
      ) {
  }

  ngOnInit() {
    this._databusService.userProfileDetails$.subscribe((profileImg: any) => {
      this.profile = profileImg;
    });
  }

  logoutFunc(): void {
    this._cookieService.deleteAll('/', '');
    this.router.navigateByUrl('/login');
    return;
  }
}
