import { Component, PLATFORM_ID, Inject } from '@angular/core';
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { CookieService } from 'ngx-cookie-service';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { DatabusService } from './shared/services/databus.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private _cookieService: CookieService,
    private _databusService: DatabusService,
  ) {
    this.checkUserLoginStatus();
  }
  
  checkUserLoginStatus(): void {
    if (this._cookieService.check("_token")) {
      this._databusService.getUserProfileDetails();
    }
  }
}
