import { Component, OnInit  } from "@angular/core";
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: [ "./privacy-policy.component.scss" ],
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})
export class PrivacyPolicyComponent implements OnInit {

  ngOnInit() {
  }
}
