<nav class="horizontal-sidebar">
 <div id="sidebar-menu">
    <ul class="header-menu custom-scrollbar">
      
              <div class="header-menu">
              
                <li [class]="menuItem.headTitle1 ? 'sidebar-main-title' : 'sidebar-list mt-1'" *ngFor="let menuItem of menuItems" [ngClass]="{ active: menuItem.active }">
                  <!-- Link -->
                  <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active" class="sidebar-link sidebar-title" [class.link-nav]="!menuItem.children" [ngClass]="{ active: menuItem.active }" *ngIf="menuItem.type === 'link'">
                      <app-feather-icons [icon]=menuItem.icon></app-feather-icons>
                      <span class="center-text">{{ menuItem.title | translate }}</span>
                      <div class="according-menu">
                          <i class="fa fa-angle-{{ menuItem.active ? 'up' : 'down' }} pull-right" *ngIf="menuItem.children"></i>
                      </div>
                  </a>
              </li>
              </div>
            
    </ul>
  </div>
 
</nav>
