import { Component, OnInit, ElementRef, ViewChild, TemplateRef, HostListener } from "@angular/core";
import { Validators, FormGroup, FormControl, AbstractControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/shared/services/api.service";
import { City, PubServices, otpRequest, otpVerification, termsandcondition, register, services, userRegisteredCount, Country,getTestimonials, landingPageFileUpload, landingPageText, referralUser } from "src/app/config/endpoints";
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DatabusService } from "src/app/shared/services/databus.service";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: [ "./login.component.scss" ],
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})
export class LoginComponent implements OnInit {
  @ViewChild('loginMain') loginMain: ElementRef;

  public newUser = false;
  public show: boolean = false
  public errorMessage: any;
  public otpReceived: boolean = false;
  public textMessage: any = '';
  public OTPCountDown: number = 10;
  public userDetail: any = null;
  public referBy: any = null;
  public selectedCity: any = null;
  public countries: any;
  public selectedCountryCode: string = '';
  public filteredCountries: any;
  public selectedCountry: any; 
  showRegisterDiv: boolean = false;
  public showMoreTAC: boolean= false
  public newUserSignUp = false;
  public TermAndCondition: boolean = false;
  @ViewChild('headDetails') headDetails!: ElementRef;

  public termsandconditions =[];
  @ViewChild("AccepctModel", { static: false }) AccepctModel: TemplateRef<any>;
  @ViewChild("Success", { static: false }) Success: TemplateRef<any>;
  owlcarousel2Options = {
    loop: true,
    margin: 10,
    lazyLoad: true,
    items: 5,
    nav: false,
    responsive: {
      300: {
        items: 1
      },
    }
  }

  public loginForm: any = new FormGroup({
    "firstName": new FormControl("", [ Validators.required]),
    "lastName": new FormControl(""),
    "countryCode" : new FormControl(""),
    "phone": new FormControl("", [ Validators.required, Validators.pattern(/^\d{10}$/) ]),
    "otp": new FormControl("", [ Validators.required, Validators.pattern(/^\d{4}$/) ]),
  });

  fullNameValidator(): any {
    console.log("aa")
    return (control: AbstractControl): { [key: string]: any } | null => {
      const fullName = control.value;
      const namePattern = /^[a-zA-Z]+ [a-zA-Z]+$/; // Regex pattern for "FirstName LastName"
      
      if (fullName && !namePattern.test(fullName)) {
        return { 'invalidFullName': { value: control.value } };
      }
      
      return null;
    };
  }
  public registrationForm: any = new FormGroup({
    "city": new FormControl("", [ ]),
  });
  showNavigationArrows = false;
  showNavigationIndicators = false;
  images = ['assets/images/big-lightgallry/01.jpg','assets/images/big-lightgallry/02.jpg','assets/images/big-lightgallry/03.jpg']
  public products: any =[];
  public userRegisteredCount:any = 0; 
  public cities: any = [];
  public testimonials : any =[];
  public user:any;
  public totalPoints :number =10;
  public footerImage=''
  public referralUser: any = '';
  public headerImage=''
  constructor(
    public router: Router,
    private _activatedRoute: ActivatedRoute,
    private _cookieService: CookieService,
    private _tosatService: ToastrService,
    private _apiService: ApiService,
    private modalService: NgbModal,
    private _databusService: DatabusService,
    config: NgbCarouselConfig
  ) {
    config.showNavigationArrows = false;
    config.showNavigationIndicators = true;
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
  }

  ngOnInit() {
    if (this._cookieService.get("_token")) {
      this.router.navigate([ "/dashboard/default" ]);
    }
    if(this._activatedRoute.snapshot.params.referral_id){
      this.referBy = this._activatedRoute.snapshot.params.referral_id;
      this.checkReferralUser(this.referBy);
    }
   
    this.getProduct();
    this.getUserRegisteredCount();
   // this.getCities();
    this.getTestimonials();
   this.getcountries();
   this.getTermsandCondition();
   this.getHeaderAndFooterImage();
   this.getLandingPageText();
  }
  getLandingPageText(): void{
    this._apiService.makeGetRequest(landingPageText).subscribe((response: any)=>{
      this.textMessage= !response?.data?.description || response?.data?.description == '' ? 'Register Now and get <b>100 Rs. </b> signup reward ': response?.data?.description;
    });
  }

  checkReferralUser(id: any): void{
    this._apiService.makeGetRequest(referralUser+'/'+id).subscribe((response: any)=>{
      this.referralUser =  response.data?.details.firstName;
    })
  }
  getHeaderAndFooterImage(): any{
    this._apiService.makeGetRequest(landingPageFileUpload).subscribe((res: any)=>{
      res.data.map((item: any)=>{
        if(item.type == 'header'){
          this.headerImage = item.path;
        }else{
          this.footerImage = item.path;
        }
      })
    });
  }
  public getProduct(): void {
    this._apiService.makeGetRequest(PubServices).subscribe((response: any) => {
      if (response.status) {
        this.products = response.data;
      }
    });
  }
  getUserRegisteredCount(): void {
    this._apiService.makeGetRequest(userRegisteredCount).subscribe((response: any) => {
        this.userRegisteredCount = response.data;
    });
  }
  scrollToLoginForm() {
    this.loginMain.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  getTermsandCondition () :void {
    this._apiService.makeGetRequest(termsandcondition).subscribe((response: any) => {
      if (response.status) {
        this.termsandconditions = response.data;
      }
    });
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (window.pageYOffset > 750) {
      this.showRegisterDiv = true;
    } else {
      this.showRegisterDiv = false;
    }
  }
  
  otpRequest(): void {
    const reqBody = { 
      "phone": this.loginForm.controls.phone.value, 
      referBy:this.referBy, 
      firstName: this.loginForm.value.firstName.split(' ')[0], // First name is Full Name so I splice with  space  and added first name and last name
      lastName: this.loginForm.value.firstName.split(' ')[1], 
    };
 
    this._apiService.makePostRequest(otpRequest, reqBody).subscribe((response: any) => {
      if (response.status) {
        if(response.data?.newUser == true){
          this.newUserSignUp = true;
        }
        this.OTPCountDownStart();
        this._tosatService.success(response.message);
        this.otpReceived = true;
      }else{
        this._tosatService.error("Please Enter Valid Phone Number.");
      }
    }, (error: any )=>{
      this._tosatService.error("Please Enter Valid Phone Number.");
    });
  }
  otpVerification(): void {
    this._apiService.makePostRequest(otpVerification, this.loginForm.value).subscribe((response) => {
      if (response.status) {
        this.newUserSignUp = false;
        this._tosatService.success(response.message);
        // If User is not registered then show registration form
        this.userDetail = response.data;
        if(response.data.newUser){
          this.newUser = response.data.newUser;
          this._cookieService.set('_id', response.data._id, 1000, '/', '', false, "Strict");
          this.onSubmit();
        }else{
          this._cookieService.set('_token', response.data.token, 1000, '/', '', false, "Strict");
          this._cookieService.set('_id', response.data._id, 1000, '/', '', false, "Strict");
          window.location.href = '/dashboard/default';
          // this.router.navigate([ "/dashboard/default" ]);
        }
        this.otpReceived = false;
      }else{
        this._tosatService.error(response.message);
      }
    }, (error: any )=>{
      this._tosatService.error("Please Enter Valid OTP","Invalid OTP");
    });
  }

  onSubmit(): void{
    const reqData = { 
      firstName: this.loginForm.value.firstName.split(' ')[0], // First name is Full Name so I splice with  space  and added first name and last name
      lastName: this.loginForm.value.firstName.split(' ')[1], 
      userId: this._cookieService.get('_id'), 
      referBy:this.referBy 
    };
    this._apiService.makePostRequest(register, reqData).subscribe(( response: any ) => {
      if (response.status) {
        this._tosatService.success("login successful","Welcome to Loan Galaxy");
        this._cookieService.set('_token', this.userDetail.token, 1000, '/', '', false, "Strict");
        this._cookieService.set('_id', this.userDetail._id, 1000, '/', '', false, "Strict");
       // window.location.href = '/dashboard/default';
        this.router.navigate([ "/dashboard/default" ]);
        this.otpReceived = false;
        this.openModal();
    
     
      }

    }, (error: any )=>{
      this._tosatService.info("Please Try with Different Mobile Number.", "Info.");
    });
    return;
  }
 
  openModal(): void {
    this.callUserProfileDetailsService();
    this.modalService.open(this.Success, {
      size: 'md',
      ariaLabelledBy: 'modal-bookmark',
      centered: true,
      windowClass: 'modal-bookmark'
    })
  }
  callUserProfileDetailsService(): void {
    this._databusService.userProfileDetails$.subscribe((user: any) => {
      this.user = user;
      this.totalPoints = parseInt(user?.rewardCount?.rewardDetails[0]?.totalPoints);
    });
  }
  OTPCountDownStart(): void {
    this.show = false;
    const countdowuninterval = setInterval(() => {
      this.OTPCountDown--;
      if (this.OTPCountDown === 0) {
        clearInterval(countdowuninterval);
        this.OTPCountDown = 10;
        this.show = true;
      }
    }, 1000);
  }
  closeAllModal(): void{
    window.location.reload();
    this.modalService.dismissAll();
  }
  
  getCities(): void {
    this._apiService.makeGetRequest(City+`/IN/MH`).subscribe((response: any) => {
      if (response.status) {
        this.cities = response.data.data;
      }
    });
  }
  getTestimonials(): void {
    this._apiService.makeGetRequest(getTestimonials).subscribe((response: any) => {
      if (response.status == 1) {
        this.testimonials = response.data;
      
      }
     
    });
  }
  getcountries(): void {
    this._apiService.makeGetRequest(Country)
  .subscribe((response: any) => {
    if (response.status) {
      this.countries = response.data.data;
      this.selectedCountry = this.countries.find(country => country.isoCode === 'IN');
    }
  });

      
  }
  customSearch(countryname: string, item: any) {
    countryname = countryname.toLowerCase();
    return item.name.toLowerCase().includes(countryname) || item.phonecode.includes(countryname);
  }
  openAccepctModel() {
    this.modalService.open(this.AccepctModel, {
        size: 'md',
        ariaLabelledBy: 'modal-other',
        centered: true,
        windowClass: 'modal-other'
    });
  
  }

  TermAndConditionClick(): void{
    this.TermAndCondition = !this.TermAndCondition;
  }
}
