<!-- Page Sidebar Start-->
<div class="logo-wrapper">
  <h5> नमस्ते 🙏,<br /><b>{{user?.firstName}}, Ji!</b></h5>
  <div class="back-btn" (click)="sidebarToggle()">
    <i class="fa fa-angle-left"></i>
  </div>
</div>

<div class="logo-icon-wrapper">
  <a href="javascript:void(0)">
    <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="" />
  </a>
</div>
<nav class="sidebar-main">
  <div class="left-arrow" id="left-arrow" [class.d-none]="leftArrowNone" (click)="scrollToLeft()">
    <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
  </div>
  <div id="sidebar-menu"
    [ngStyle]="{ marginLeft: this.layout.config.settings.layout == 'Rome' || 'Singapore' || 'Barcelona' ? margin + 'px' : '0px' }">
    <ul class="sidebar-links custom-scrollbar">
      <div class="simplebar-wrapper">
        <div class="simplebar-mask">
          <div class="simplebar-offset">
            <div class="simplebar-content-wrapper">
              <div class="simplebar-content">
                <li class="back-btn">
                  <a href="javascript:void(0)">
                    <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="" />
                  </a>
                  <div class="mobile-back text-end" (click)="sidebarToggle()">
                    <span>Back</span>
                    <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
                  </div>
                </li>
                <li class="center-content">
                  <div class="card balance-box">
                    <div class="card-body">
                      <div class="balance-profile">
                        <div class="balance-img">
                          <img src="https://angular.pixelstrap.com/cuba/assets/images/dashboard-4/user.png"
                            alt="user vector" />
                        </div>
                        <span class="f-light d-block">{{user?.firstName || ''}} {{user?.lastName}} </span>
                        <a routerLink="user/edit-profile">
                          <span>Edit Profile</span>
                        </a>
                        <hr>
                        <div class="d-flex mb-2  balance-item danger">
                          <div class="balance-icon-wrap">
                            <div class="balance-icon">
                              <app-feather-icons
                                [icon]="'life-buoy'">
                              </app-feather-icons></div>
                          </div>
                          <div>
                            <span class="f-12 f-light">No of Referrals : <b>{{counts?.referralCount}}</b></span>
                         
                          </div>
                        </div>
                        <div class="d-flex mb-2 align-items-center  balance-item success">
                          <div class="balance-icon-wrap">
                            <div class="balance-icon"><app-feather-icons
                                [icon]="'credit-card'"></app-feather-icons></div>
                          </div>
                          <div>
                            <span class="f-12 f-light">No of Purchase : <b>{{ purchaseCount || 0 }}</b></span>
                         
                          </div>
                        </div>
                        <div class="d-flex align-items-center  balance-item danger">
                          <div class="balance-icon-wrap">
                            <div class="balance-icon"><app-feather-icons
                                [icon]="'check-circle'"></app-feather-icons></div>
                          </div>
                          <div>
                            <span class="f-12 f-light">Reward Bonus : <b>₹ {{ totalCashReward || 0 }}</b></span>
                         
                          </div>
                        </div>
                      
                        <!-- <ul>
                          <li>
                          
                          </li>
                          <li>
                           
                          </li>
                        </ul> -->
                      </div>
                    </div>
                  </div>
                </li>
                <li class="center-content p-3">
                  <a [routerLink]="'/user/basket'">
                    <img class="img-fluid w-50"
                      src="assets/images/qr-code-icon-qr-code-sample-for-smartphone-scanning-isolated-illustration-vector.png"
                      alt="" />
                    <h6 class="menuItem headTitle1">Check Your Basket</h6>
                  </a>
                </li>
                <li class="center-content">
                  <button class="btn btn-primary lan-1 ml-2register p-2" type="button" (click)="logout()">
                    <span>Log Out</span>
                  </button>
                </li>
                <!-- Social Links -->
                <li class="center-content">
                  <div class="mt-5 flex">
                    
                        <a href="javascript:void(0)">
                          <i class="fa fa-facebook text-md" style="color: #316FF6;font-size:20px"></i>
                        </a>
                        <a href="javascript:void(0)">
                          <i class="fa fa-twitter" style="color:#1DA1F2 ;font-size:20px;"></i>
                        </a>
                        <a href="javascript:void(0)">
                          <i class="fa fa-instagram" style="color: #e94e33;font-size:20px;"></i>
                        </a>
                        <a href="javascript:void(0)">
                          <i class="fa fa-linkedin" style="color: #0A66C2;font-size:20px;"></i>
                        </a>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ul>

  </div>
  <div class="right-arrow" id="right-arrow" [class.d-none]="rightArrowNone" (click)="scrollToRight()">
    <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
  </div>
</nav>