<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 footer-copyright text-center">
      <p class="mb-0">Copyright {{ today | date:'y'}} © Loan Galaxy Developed by DevRevel  </p>
    </div>
  </div>
</div>

<div class="whatsnew-btn"><a class="btn" (click)="copyURL()" href=""
  style="z-index: -1;background-color: #25D366;">
  <b class="invite-b">
    <i class="fa fa-whatsapp WhatsAppIcon" aria-hidden="true"></i>
    Invite Your Friends
  </b>
</a></div>