/******************* Auth API Endpoints ********************/
export const otpRequest = 'auth/otp-request';
export const register = 'auth/register';
export const otpVerification = 'auth/otp-verification';
export const userRegisteredCount = 'auth/user-registered-count';

/******************* Product API Endpoints ********************/
export const services = 'pri/service';
export const PubServices = 'pub/service';

/******************* User API Endpoints ********************/
export const user = 'pri/user';
export const rewards = 'pri/rewards'



/******************* Referral API Endpoints ********************/
export const referral = 'pri/referrals';
export const leaderBoard  = 'pri/leader-board';

/******************* Bank Details ********************/
export const bank = 'pri/bank';
export const enquiry = 'pri/enquiry';
export const enquiryUser = 'pri/enquiry-user';
export const userCounts = 'pri/user/counts';
export const subServices = 'pri/subservice'; 

export const lead = 'pri/lead';

/******************* Media Details ********************/
export const faq = 'pri/faq';
export const pdf = 'pri/pdf';
export const video = 'pri/video';
export const termsandcondition = 'pub/termsandcondition';


export const City = 'pub/getCity';
export const Country = 'pub/getCountry'
export const getTestimonials = 'pub/testimonials'

export const landingPageFileUpload = 'pub/landing-page-image-upload';
export const landingPageText = 'pub/landing-page-text'
export const friendInviteMessage =  'pri/friend-invite-message';
export const referralUser = 'pub/referral-user'